import { GatsbyProps } from 'models/gatsby-props';
import { createContext, useState } from 'react';
import { UserCheck } from 'components/user-check';
import { isSafari } from 'react-device-detect';

interface State {
    loaded: boolean;
    authenticated: boolean;
}

const initialState: State = {
    loaded: process.env.GATSBY_ENVIRONMENT === 'development' || isSafari,
    authenticated: false
};

export const AppStateContext = createContext<State | null>(null);

const AppContextProvider = ({ location, children }: GatsbyProps) => {
    const [state, setState] = useState(initialState);

    return (
        <AppStateContext.Provider value={state}>
            {process.env.GATSBY_ENVIRONMENT === 'production' && !isSafari ? (
                <UserCheck
                    handleLoggedInUser={() => setState({ loaded: true, authenticated: true })}
                    handleNotLoggedInUser={() => setState({ loaded: true, authenticated: false })}
                    location={location}
                />
            ) : null}
            {children}
        </AppStateContext.Provider>
    );
};

export default AppContextProvider;
